import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Header from "./header";

import "./layout.css";

const theme = {
  blue: "#0632D3",
  grey: "#494949",
};

const GlobalStyle = createGlobalStyle`
  a {
    color: #0632D3;
    font-weight: bold;
    text-decoration: none;
  }
  html {
    background-color: ${(props) => props.colouredBackground && "#0632D3"};
  }
  p{
    color: ${(props) => (props.colouredBackground ? "white" : "#0632D3")};
    font-size: 0.95em;
  }
  h1 {
    font-size: 2em;
  }
`;

const Layout = ({
  children,
  fullWidth,
  colouredBackground = false,
  hideNav = false,
}) => {
  return (
    <main>
      <GlobalStyle colouredBackground={colouredBackground} />
      <ThemeProvider theme={theme}>
        {!hideNav && <Header reverse={!!colouredBackground} />}
        <Wrapper fullWidth={fullWidth}>{children}</Wrapper>
      </ThemeProvider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const Wrapper = styled.div`
  margin: 0 auto;
  ${({ fullWidth }) =>
    !fullWidth &&
    `max-width: 960px; padding: 1em; margin-bottom: 2em;
    margin: 2em auto;
  }`};
  .animations-page {
    max-width: 600px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 800px) {
    ${({ fullWidth }) =>
      !fullWidth &&
      `padding: 80px 1em 2em 1em;;
  }`};
  }
`;
